import React, { Component } from 'react'
import ClientComponent from '../components/clients/ClientComponent'
import ClientFilters from '../components/clients/ClientFilters'
import ClientSuccessCarousel from "../components/clients/ClientSuccessCarousel"
import { clientDataFilter } from '../staticdata/caseStudySectionData'
class ClientSuccessContainer extends Component {

    state = {
        selectedServices: [],
        selectedIndustries: []
    }

    selectService = (selectedService) => {
        if (selectedService === 'All') {
            this.setState({ selectedServices: [] })
            return
        }
        const { selectedServices } = this.state
        let services = selectedServices.map(service => service)
        if (selectedServices.indexOf(selectedService) === -1)
            services = services.concat([selectedService])
        this.setState((prev) => ({
            selectedServices: services
        }))
    }

    selectIndustry = (selectedIndustry) => {
        if (selectedIndustry === 'All') {
            this.setState({ selectedIndustries: [] })
            return
        }
        const { selectedIndustries } = this.state
        let services = selectedIndustries.map(service => service)
        if (selectedIndustries.indexOf(selectedIndustry) === -1)
            services = services.concat([selectedIndustry])
        this.setState((prev) => ({
            selectedIndustries: services
        }))
    }

    removeItem = (item, type) => {
        const { selectedServices, selectedIndustries } = this.state
        if (type === 'all') {
            this.setState({
                selectedServices: [],
                selectedIndustries: []
            })
            return
        }
        if (type === 'service') {
            let filteredData = selectedServices.filter(service => service !== item)
            this.setState({ selectedServices: filteredData })
        }
        if (type === 'industry') {
            let filteredData = selectedIndustries.filter(service => service !== item)
            this.setState({ selectedIndustries: filteredData })
        }
        return false
    }
    filterClientData = () => {
        const { selectedIndustries, selectedServices } = this.state
        if (selectedIndustries.length === 0 && selectedServices.length === 0)
            return clientDataFilter
        let filterData = []
        let dummyData = []
        clientDataFilter.map(client => {
            client.serviceType.map(clientService => {
                if (selectedServices.indexOf(clientService) > -1)
                    filterData.push(client)
                    return false
            })
            client.industryType.map(clientIndustry => {
                if (selectedIndustries.indexOf(clientIndustry) > -1)
                    filterData.push(client)
                    return false
            })
            return false
        })
        let finalData = []
        filterData.map(item => {
            if(dummyData.indexOf(item.redirection) < 0){
                dummyData.push(item.redirection)
                finalData.push(item)
            }
            return false
        })
        return finalData
    }
    render() {
        const { selectedServices, selectedIndustries } = this.state
        const filteredClientData = this.filterClientData()
        return (
            <>
                <ClientSuccessCarousel />
                <ClientFilters
                    data={filteredClientData}
                    selectService={this.selectService}
                    selectedService={selectedServices}
                    selectIndustry={this.selectIndustry}
                    selectedIndustries={selectedIndustries}
                    removeItem={this.removeItem} />
                <ClientComponent
                    data={filteredClientData}
                />

            </>
        )
    }
}

export default ClientSuccessContainer